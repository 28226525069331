import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="binance-copy-trading" className="copy-trading-section">
      <div className="container">
        <h2>Binance Copy Trading Guide</h2>
        <p>Copy trading allows users to copy the trades of experienced traders automatically. Here's a quick guide on how to start:</p>
        <ul>
          <li><strong>Step 1:</strong> Open a Binance account and deposit funds.</li>
          <li><strong>Step 2:</strong> Choose a trader to copy based on their performance.</li>
          <li><strong>Step 3:</strong> Start copying trades automatically.</li>
        </ul>
        <a
          href="https://academy.binance.com/en/articles/your-guide-to-binance-copy-trading"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read the Full Guide
        </a>
      </div>
    </section>
    </div>
    
  );
};
